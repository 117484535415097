import { Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import Web3 from 'web3'

import { mapToDgWrappedToken } from '../helpers/wrappedTokenHelpers'
import {
  Address,
  TokenId,
  TokenNetwork,
  TokenV3,
  TokenWithApprovalContext,
  UpdateTokenPrice,
} from '../model'
import { State } from '../reducers'
import { SetGlobalLoaderVisibleAction } from '../reducers/loaderReducer'
import {
  ADD_FAVORITE_TO_SYNC_QUEUE,
  AddFavoriteToSyncQueueAction,
  FAIL_ADD_FAVORITE_TOKEN,
  FAIL_FETCH_TOKENS,
  FAIL_REMOVE_FAVORITE_TOKEN,
  FAIL_SYNC_FAVORITES,
  FailFetchTokens,
  LoadingAction,
  MERGE_TOKENS,
  MergeTokensAction,
  NOTHING_TO_SYNC_FAVORITES,
  NothingToSyncFavoritesAction,
  REMOVE_FAVORITE_TO_SYNC_QUEUE,
  RemoveFavoriteToSyncQueueAction,
  SET_CURRENT_TOKEN,
  SET_FAVORITES,
  SET_QUOTE_TOKEN,
  SET_TOKEN_LOADING,
  SetCurrentTokenAction,
  SetFavoritesAction,
  SetQuoteTokenAction,
  START_ADD_FAVORITE_TOKEN,
  START_FETCH_TOKENS,
  START_REMOVE_FAVORITE_TOKEN,
  START_SYNC_FAVORITES,
  StartFetchTokens,
  STORE_TOKENS,
  StoreTokensAction,
  SUCCESS_ADD_FAVORITE_TOKEN,
  SUCCESS_REMOVE_FAVORITE_TOKEN,
  SUCCESS_SYNC_FAVORITES,
  TokenAction,
  TokensState,
  UPDATE_CURRENT_TOKEN,
  UPDATE_QUOTE_TOKEN,
  UPDATE_TOKEN_PRICES,
  UPDATE_TOKENS,
  UpdateCurrentTokenAction,
  UpdateQuoteTokenAction,
  UpdateTokenPricesAction,
  UpdateTokensAction,
} from '../reducers/tokens'
import apiClient from '../services/ApiClient'
import {
  addFavoriteToLocalStarage,
  removeFavoriteToLocalStarage,
} from '../services/preferencesService'
import { initCurrentToken, loadCurrentQuoteTokens } from '../services/tokenService'
import { getAvailableNetworks, isNativeTokenForNetwork } from '../utils'
import { setGlobalLoader } from './loaderActions'

export const storeCurrentQuoteTokens = (
  currentToken: TokenWithApprovalContext,
  library: Web3,
  network?: TokenNetwork,
  quoteToken?: TokenWithApprovalContext,
  account?: Address | null
): ThunkAction<void, TokensState, void, StoreTokensAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
    const storedTokens = await loadCurrentQuoteTokens(
      currentToken,
      library,
      network,
      quoteToken,
      account
    )
    if (storedTokens.quoteToken && isNativeTokenForNetwork(storedTokens.quoteToken)) {
      dispatch(updateTokens([mapToDgWrappedToken(storedTokens.quoteToken)]))
    }
    if (storedTokens.currentToken && isNativeTokenForNetwork(storedTokens.currentToken)) {
      dispatch(updateTokens([mapToDgWrappedToken(storedTokens.currentToken)]))
    }

    dispatch<StoreTokensAction>({
      type: STORE_TOKENS,
      account,
      ...storedTokens,
    })

    dispatch(setGlobalLoader(false))
  }
}

export const initTokens =
  (
    tokenId: TokenId,
    account: Address | null | undefined,
    library: Web3,
    network?: TokenNetwork
  ): ThunkAction<Promise<void>, State, unknown, TokenAction | SetGlobalLoaderVisibleAction> =>
  async (dispatch): Promise<void> => {
    try {
      dispatch({ type: SET_TOKEN_LOADING, status: true })
      const currentToken = await initCurrentToken(tokenId, account)

      if (currentToken) {
        dispatch({ type: SET_CURRENT_TOKEN, currentToken })
        if (isNativeTokenForNetwork(currentToken)) {
          dispatch(updateTokens([mapToDgWrappedToken(currentToken)]))
        }

        dispatch({ type: SET_TOKEN_LOADING, status: false })

        const { quoteToken } = await loadCurrentQuoteTokens(
          currentToken,
          library,
          network,
          undefined,
          account
        )

        if (quoteToken) {
          dispatch({ type: SET_QUOTE_TOKEN, quoteToken })

          if (isNativeTokenForNetwork(quoteToken)) {
            dispatch(updateTokens([mapToDgWrappedToken(quoteToken)]))
          }
        } else {
          throw new Error('tokens init error')
        }
      } else {
        throw new Error('tokens init error')
      }
    } catch (error) {
      console.error('init tokens error', error)
      dispatch(setGlobalLoader(true))
    }
  }

export const setCurrentToken = (currentToken: TokenWithApprovalContext): SetCurrentTokenAction => ({
  type: SET_CURRENT_TOKEN,
  currentToken,
})

export const updateCurrentToken = (
  currentToken: Partial<TokenWithApprovalContext>
): UpdateCurrentTokenAction => {
  return {
    type: UPDATE_CURRENT_TOKEN,
    currentToken,
  }
}

export const updateQuoteToken = (
  quoteToken: Partial<TokenWithApprovalContext>
): UpdateQuoteTokenAction => {
  return {
    type: UPDATE_QUOTE_TOKEN,
    quoteToken,
  }
}

export const setQuoteToken = (quoteToken: TokenWithApprovalContext): SetQuoteTokenAction => ({
  type: SET_QUOTE_TOKEN,
  quoteToken,
})

export const updateTokens = (tokens: TokenV3[]): UpdateTokensAction => ({
  type: UPDATE_TOKENS,
  tokens,
})

export const updateTokenPrices = (tokenPrices: UpdateTokenPrice[]): UpdateTokenPricesAction => ({
  type: UPDATE_TOKEN_PRICES,
  tokenPrices,
})

export const setLoading = (status = true): LoadingAction => ({
  type: SET_TOKEN_LOADING,
  status,
})

const addFavoriteToSyncQueue = (tokenId: TokenId): AddFavoriteToSyncQueueAction => ({
  type: ADD_FAVORITE_TO_SYNC_QUEUE,
  payload: tokenId,
})

const removeFavoriteToSyncQueue = (tokenId: TokenId): RemoveFavoriteToSyncQueueAction => ({
  type: REMOVE_FAVORITE_TO_SYNC_QUEUE,
  payload: tokenId,
})

const hasToSync = (state: State): boolean => state.tokens.syncFavoritesQueue.length > 0

const nothingToSync = (): NothingToSyncFavoritesAction => ({ type: NOTHING_TO_SYNC_FAVORITES })

const syncFavorites =
  (): ThunkAction<Promise<void>, State, unknown, TokenAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState()
    if (!hasToSync(state)) {
      dispatch(nothingToSync())
      return
    }
    const syncQueue = state.tokens.syncFavoritesQueue
    const addQueue = new Set<TokenId>()
    const removeQueue = new Set<TokenId>()
    try {
      syncQueue.forEach((syncAction) => {
        if (syncAction.action === 'add') {
          addQueue.add(syncAction.tokenId)
        }
        removeQueue.add(syncAction.tokenId)
      })
      dispatch({ type: START_SYNC_FAVORITES })

      if (addQueue.size) {
        await apiClient.settings.addToFavourites(Array.from(addQueue))
      }
      if (removeQueue.size) {
        await apiClient.settings.removeFromFavourites(Array.from(removeQueue))
      }

      dispatch({ type: SUCCESS_SYNC_FAVORITES })
    } catch (error) {
      console.error((error as Error).message)
      dispatch({ type: FAIL_SYNC_FAVORITES, payload: syncQueue })
    }
  }

export const addFavorite =
  (tokenId: TokenId): ThunkAction<Promise<void>, State, unknown, TokenAction> =>
  async (dispatch, getState): Promise<void> => {
    try {
      const { isUpdatingFavorites } = getState().tokens

      if (isUpdatingFavorites) {
        dispatch(addFavoriteToSyncQueue(tokenId))
        return
      }

      dispatch({ type: START_ADD_FAVORITE_TOKEN, payload: [tokenId] })

      // save to local storage
      addFavoriteToLocalStarage(tokenId)

      const response = await apiClient.settings.addToFavourites([tokenId])
      if (response.error) {
        throw new Error(response.error?.message)
      }

      dispatch({ type: SUCCESS_ADD_FAVORITE_TOKEN, payload: tokenId })
      dispatch(syncFavorites())
    } catch (error) {
      // TODO: error message is tokens state
      dispatch({ type: FAIL_ADD_FAVORITE_TOKEN, payload: tokenId })
    }
  }

export const removeFavorite =
  (tokenId: TokenId): ThunkAction<Promise<void>, State, unknown, TokenAction> =>
  async (dispatch, getState): Promise<void> => {
    try {
      const { isUpdatingFavorites } = getState().tokens

      if (isUpdatingFavorites) {
        dispatch(removeFavoriteToSyncQueue(tokenId))
        return
      }

      dispatch({ type: START_REMOVE_FAVORITE_TOKEN, payload: tokenId })

      removeFavoriteToLocalStarage(tokenId)

      const response = await apiClient.settings.removeFromFavourites([tokenId])
      if (response.error) {
        throw new Error(response.error?.message)
      }
      dispatch({ type: SUCCESS_REMOVE_FAVORITE_TOKEN, payload: tokenId })
      dispatch(syncFavorites())
    } catch (error) {
      dispatch({ type: FAIL_REMOVE_FAVORITE_TOKEN, payload: tokenId })
    }
  }

export const addFavouriteList =
  (tokenIds: Array<TokenId>): ThunkAction<Promise<void>, State, unknown, TokenAction> =>
  async (dispatch): Promise<void> => {
    try {
      dispatch({ type: START_ADD_FAVORITE_TOKEN, payload: tokenIds })

      const response = await apiClient.settings.addToFavourites(tokenIds)
      if (response.error) {
        throw new Error(response.error?.message)
      }

      dispatch({ type: SUCCESS_ADD_FAVORITE_TOKEN })
      dispatch(syncFavorites())
    } catch (error) {
      // TODO: error message is tokens state
      dispatch({ type: FAIL_ADD_FAVORITE_TOKEN })
    }
  }

export const mergeTokens = (tokens: TokenV3[]): MergeTokensAction => ({
  type: MERGE_TOKENS,
  tokens,
})

export const setFavorites = (tokenIds: TokenId[]): SetFavoritesAction => ({
  type: SET_FAVORITES,
  tokenIds,
})

const startFetchTokens = (): StartFetchTokens => ({
  type: START_FETCH_TOKENS,
})

const failFetchTokens = (errorMessage: string): FailFetchTokens => ({
  type: FAIL_FETCH_TOKENS,
  payload: errorMessage,
})

export const refreshTokens =
  (tokenIds: TokenId[]): ThunkAction<Promise<void>, State, unknown, TokenAction> =>
  async (dispatch, getState): Promise<void> => {
    dispatch(startFetchTokens())

    const networksConfigState = getState().networksConfig
    const availableNetworks = getAvailableNetworks(networksConfigState).join(',')

    const response = await apiClient.tokens.loadList({ tokenIds, availableNetworks })
    const tokenListData = response.responseData?.data

    if (response.error || !tokenListData) {
      dispatch(failFetchTokens(response.error?.message || 'other_error'))
      return
    }

    if (!tokenListData?.length) {
      return
    }

    dispatch(updateTokens(tokenListData))
  }
