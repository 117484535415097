import { ThunkAction } from 'redux-thunk'

import { TraderProfileArgs, TransactionModel } from '../model'
import { State } from '../reducers'
import {
  DROP_TRADER_PROFILE,
  DropTraderProfile,
  SET_TRADER_PROFILE_CHART,
  SET_TRADER_PROFILE_CHART_ID,
  SET_TRADER_PROFILE_LOADING,
  SET_TRADER_PROFILE_PREV_PATHNAME,
  SET_TRADER_PROFILE_TAB,
  SET_TRADER_PROFILE_TOTALS,
  SET_TRADER_PROFILE_TXN,
  SET_TRADER_PROFILE_WALLET,
  SetTraderProfileActionChart,
  SetTraderProfileActionChartId,
  SetTraderProfileActionPrevPathname,
  SetTraderProfileActionTotals,
  SetTraderProfileActionTxn,
  SetTraderProfileActionWallet,
  SetTraderProfileLoading,
  SetTraderProfileTab,
  TraderProfileAction,
  TraderProfileState,
  TraderProfileTabs,
} from '../reducers/traderProfileReducer'
import {
  RESET_PROFILE_TRADING_HISTORY,
  ResetProfileTradingHistory,
  SET_PROFILE_TRADING_HISTORY,
  SetProfileTradingHistory,
} from '../reducers/tradingHistory'

export const setProfileTradingHistory = (
  currentProfileTradingHistory?: TransactionModel[]
): SetProfileTradingHistory => ({
  type: SET_PROFILE_TRADING_HISTORY,
  currentProfileTradingHistory,
})

export const resetProfileTradingHistory = (): ResetProfileTradingHistory => ({
  type: RESET_PROFILE_TRADING_HISTORY,
})

export const setTraderProfileTotals = ({
  walletAddress,
  walletCategory,
  assetsValue,
  assetsValueChange24H,
  tradingVolume,
  tradingVolumeChange30D,
  balances,
  holderType,
  label,
}: TraderProfileArgs): SetTraderProfileActionTotals => ({
  type: SET_TRADER_PROFILE_TOTALS,
  payload: {
    walletAddress,
    walletCategory,
    assetsValue,
    assetsValueChange24H,
    tradingVolume,
    tradingVolumeChange30D,
    balances,
    holderType,
    label,
  },
})

export const setTraderProfileLoading = ({
  isLoading,
  reqAllowed,
}: Partial<TraderProfileArgs>): SetTraderProfileLoading => ({
  type: SET_TRADER_PROFILE_LOADING,
  payload: { isLoading, reqAllowed },
})

export const dropTraderProfile = ({
  pathname,
}: Partial<TraderProfileState>): DropTraderProfile => ({
  type: DROP_TRADER_PROFILE,
  payload: { pathname },
})

export const setTraderProfileWallet = ({
  walletAddress,
}: TraderProfileArgs): SetTraderProfileActionWallet => ({
  type: SET_TRADER_PROFILE_WALLET,
  payload: { walletAddress },
})

export const setTraderProfileChartId = ({
  shownChartId,
}: TraderProfileArgs): SetTraderProfileActionChartId => ({
  type: SET_TRADER_PROFILE_CHART_ID,
  payload: { shownChartId },
})

export const setTraderProfileChart = ({
  chart,
}: TraderProfileArgs): SetTraderProfileActionChart => ({
  type: SET_TRADER_PROFILE_CHART,
  payload: { chart },
})

export const setTraderProfileTxn = ({
  activeTxn,
}: TraderProfileArgs): SetTraderProfileActionTxn => ({
  type: SET_TRADER_PROFILE_TXN,
  payload: { activeTxn },
})

export const setTraderProfilePrevPathname = ({
  pathname,
}: TraderProfileArgs): SetTraderProfileActionPrevPathname => ({
  type: SET_TRADER_PROFILE_PREV_PATHNAME,
  payload: { pathname },
})

export const setTraderProfileTab = ({ tab }: TraderProfileArgs): SetTraderProfileTab => ({
  type: SET_TRADER_PROFILE_TAB,
  payload: { tab },
})

export const openProfile =
  ({
    walletAddress,
    transactionAddress,
    prevPathname,
    tab,
  }: {
    walletAddress: string
    transactionAddress: string
    prevPathname: string
    tab: TraderProfileTabs
  }): ThunkAction<void, State, unknown, TraderProfileAction> =>
  (dispatch): void => {
    dispatch(setTraderProfileWallet({ walletAddress: walletAddress.toLowerCase() }))
    dispatch(setTraderProfilePrevPathname({ pathname: prevPathname }))
    dispatch(setTraderProfileTxn({ activeTxn: transactionAddress }))
    dispatch(setTraderProfileTab({ tab: tab }))
  }
